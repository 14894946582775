import React from 'react'
import styled from '@emotion/styled'
import Section from 'src/components/Section'
import Grid from 'src/components/Grid'
import Column from 'src/components/Column'
import TextLockup from 'src/components/TextLockup'
import { withShopifyContext } from 'src/state/ShopifyState'
import { colors, typography, animations, mq, util } from 'src/styles'
import ProductPreview from 'src/components/ProductPreview'
import Image from 'src/components/Image'
import Img from 'gatsby-image'

const CollectionWrapper = styled.div`
  justify-self: center;
`

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  p.placeholder {
    /* placeholder text for alignment */
    ${ typography.bodySmall }
    font-weight: 500;
    padding: 8px 0 0 0;
    margin: 0;
    flex-grow: 0;
    color: transparent;
    ${ mq.mediumAndBelow } {
      display: none;
    }
  }
`

const ImageWrapper = styled.div`
  position: relative;
  height: 100%;
  flex-grow: 1;
  ${ mq.mediumAndBelow } {
    height: unset;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${ ({ cardAlignment }) => cardAlignment === 'right' ? 'flex-end' : 'flex-start' };
  .gatsby-image-wrapper {
    background: ${ colors.lightGrey };
  }
  .gatsby-image-wrapper,
  .gatsby-image-wrapper > div {
    width: 100%;
    height: 100%;
  }
`

const Overlay = styled.div`
  text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;
  position: absolute;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${ colors.offwhite };
`

const Collection = ({
  className,
  nextTheme,
  prevTheme,
  theme,
  title,
  displayTitle,
  collectionDescription,
  collection,
  isFirstSection,
  shopifyContext,
  collectionHandle,
  cardAlignment,
  cardImage,
  cardOverlay,
  featuredImage
}) => {
  const { collections } = shopifyContext
  const thisCollection = collections.find(x => x.handle === collectionHandle)
  if (!thisCollection) return ''
  const description = collectionDescription && collectionDescription.collectionDescription
  if (featuredImage && featuredImage.fluid) {
    featuredImage.fluid.sizes = '(max-width: ' + mq.mediumBreakpoint + 'px) 86vw, 50vw'
  }
  return (
    <Section
      className={className}
      prevTheme={prevTheme}
      setTheme={theme}
      nextTheme={nextTheme}
      isFirstSection={true}
      padded={true}
    >
      <Grid small='[1]' medium='[1]' large='[1]' rowGap={['7vw', '3.8vw', '3.5vw']}>

        {featuredImage && (
          <Grid
            small='1 [12] 1'
            medium='1 [12] 1'
            large='1 [12] [12] 1'
            rowGap={['7vw', '3.8vw', '3.5vw']}
            colGap={['14px', '16px', '20px']}
          >
            <CardWrapper>
              <ImageWrapper cardAlignment={cardAlignment}>
                <Img fluid={featuredImage.fluid} />
                <Overlay>
                  <Grid
                    small="1 [12] 1"
                    medium="1 [10] 1"
                    large="3 [18] 3"
                  >
                    <TextLockup
                      headlineSize="h1Alt"
                      headline={displayTitle}
                      text={description}
                    />
                  </Grid>
                </Overlay>
              </ImageWrapper>
              <p className="placeholder">-</p>
            </CardWrapper>
            <div>
              <CollectionWrapper>
                <Grid
                  small='[1] [1]'
                  medium='[1] [1]'
                  large='[1] [1]'
                  rowGap={['7vw', '3.8vw', '3.5vw']}
                  colGap={['14px', '16px', '20px']}
                >
                  {(thisCollection && thisCollection.products) && (
                    thisCollection.products.slice(0, 4).map((product, index) => (
                      <ProductPreview
                        key={`${ thisCollection.id }_${ product.id }_${ index }`}
                        product={product}
                      />
                    ))
                  )}
                </Grid>
              </CollectionWrapper>
            </div>
          </Grid>
        )}
        <Grid
          small='1 [6] [6] 1'
          medium='1 [6] [6] 1'
          large='1 [6] [6] [6] [6] 1'
          rowGap={['7vw', '3.8vw', '3.5vw']}
          colGap={['14px', '16px', '20px']}
        >
          {(thisCollection && thisCollection.products)
            ? thisCollection.products
              .slice(featuredImage ? 4 : 0)
              .map((product, index) => (
                <ProductPreview
                  key={`${ thisCollection.id }_${ product.id }_${ index }`}
                  product={product}
                />
              ))
            : ''}
        </Grid>

      </Grid>
    </Section>
  )
}

export default withShopifyContext(Collection)
