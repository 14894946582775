import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import * as PropTypes from 'prop-types'
import { withShopifyContext } from 'src/state/ShopifyState'

import SEO from 'src/components/SEO'
import ComponentRenderer from 'src/components/ComponentRenderer'
import Header from 'src/components/Header'
import Footer from 'src/components/Footer'
import ProductAboveTheFold from 'src/components/ProductAboveTheFold'
import Collection from 'src/components/Collection'
import ShopNavigation from 'src/components/ShopNavigation'
import Drawer from 'src/components/Drawer'
import MobileMenu from 'src/components/MobileMenu'
import ResponsiveComponent from 'src/components/ResponsiveComponent'

const propTypes = {
  data: PropTypes.object.isRequired,
}

class PageTemplate extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }
  render () {
    const { pageContext = {} } = this.props
    const { handle } = pageContext
    const site = this.props.data.allContentfulSiteSettings && this.props.data.allContentfulSiteSettings.edges.filter(edge => !edge.node.internalName.includes('PLACEHOLDER'))[0].node
    const collection = this.props.data.allContentfulCollection.edges[0] && this.props.data.allContentfulCollection.edges[0].node
    const collections = this.props.data.allCollections.edges && this.props.data.allCollections.edges
    const seo = (collection && collection.seo) || site
    const footerTheme = collection && collection.footerTheme
    const { sections } = collection || {}
    return (
      <Fragment>
        <SEO
          title={collection && collection.displayTitle}
          description={seo && seo.description && seo.description.description}
          siteSettings={site}
          keywords={seo && seo.keywords}
          shareImage={seo && seo.shareImage && 'https:' + seo.shareImage.file.url}
        />
        <Header
          productPage={true}
          bannerText={site && site.bannerText}
          bannerColor={site && site.bannerColor}
          navigation={site && site.navigation}
        />

        <Drawer />
        <ResponsiveComponent
          small={
            <MobileMenu
              navLinks={site && site.navigation}
            />
          }
          large={<span />}
        />
        <ShopNavigation
          current={collection && collection.collectionHandle}
          navigation={site && site.navigation}
        />
        {collection ? <Collection {...collection} isFirstSection /> : ''}
        {/* {product && <ProductAboveTheFold product={product} /> } */}
        {sections && sections.map((section, index) => {
          const prevTheme = ((index !== 0) && sections[index - 1]) && sections[index - 1].theme
          const prevFullWidth = ((index !== 0) && sections[index - 1]) && sections[index - 1].width === 'fullWidth'
          const nextTheme = ((index !== sections.length - 1) && sections[index + 1]) && sections[index + 1].theme
          const nextFullWidth = ((index !== sections.length - 1) && sections[index + 1]) && sections[index + 1].width === 'fullWidth'
          const lastSection = sections.length === index + 1
          return (
            <ComponentRenderer
              prevTheme={prevFullWidth ? false : prevTheme}
              nextTheme={nextFullWidth ? false : nextTheme}
              isFirstSection={index === 0}
              isLastSection={lastSection}
              key={`${ section.id }_${ index }`}
              item={section}
              index={index}
            />
          )
        })}

        <Footer {...site} footerTheme={footerTheme} />
      </Fragment>
    )
  }
}

PageTemplate.propTypes = propTypes

export const productQuery = graphql`
  query ($handle: String!){
		allContentfulSiteSettings {
	    edges {
	      node {
	        ...SiteSettings
	      }
	    }
	  }
    allCollections: allContentfulCollection(filter: {internalName: {nin: "PLACEHOLDER Collection"}}) {
      edges {
        node {
          id
          collectionHandle
          displayTitle
        }
      }
    }
    allContentfulCollection(filter: {collectionHandle: {eq: $handle}}) {
      edges {
        node {
          id
          collectionHandle
          displayTitle
          collectionDescription {
            collectionDescription
          }
          featuredImage {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          sections {
            ...Column
            ...Columns
            ...ProductGrid
            ...TextSection
            ...WideMedia
          }
          footerTheme
        }
      }
    }
	}
`

export default PageTemplate
